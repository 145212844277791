import React, { useEffect, useState } from "react";
import { TextInput, Text, View, TouchableOpacity } from "react-native";
import { CurrencyFormatter } from "../../common/functions";
import { ColorType } from "native-base/lib/typescript/components/types";
import { IQuote } from "../../common/Interfaces";
import { DEFAULT_COSTS_MIN_MARGIN, DEFAULT_COSTS_SUGGESTED_MARGIN } from "../../common/config";
import { FinalQuoteDuplicateQuote } from "./ModalDuplicateQuote";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Modal, Divider } from "@mui/material";

export const FinalQuoteTotalInput = ({
  showModal,
  quote,
  onSave,
  onCancel,
}: {
  showModal: boolean;
  quote: IQuote;
  onSave: (input: number) => void;
  onCancel: () => void;
}) => {
  const [input, setInput] = useState<number>(quote.subtotal);
  const [margin, setMargin] = useState<number>(0);

  const min_suggested_total = -quote.cost / (0.4 - 1);
  const suggested_total = -quote.cost / (0.7 - 1);

  useEffect(() => {
    const value = input > 0 ? input : quote.subtotal;
    setMargin(1 - quote.cost / value);
  }, [quote.cost, quote.margin, margin, input, showModal, onCancel]);

  const resetModalInput = () => {
    setInput(0);
    onCancel();
  }

  return (
    <Modal
      open={showModal}
      onClose={resetModalInput}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          height: "47%",
          width: "25%",
          backgroundColor: "#FFFFFF",
          paddingVertical: "1%",
          borderRadius: 8,
        }}
      >
        <View style={{ flexDirection: "row", justifyContent: "space-between", flex: 1, marginHorizontal: "5%" }}>
          <Text style={{ fontSize: 24, fontWeight: "bold", color: "#671E75" }}>Editar total</Text>
          <TouchableOpacity onPress={resetModalInput}>
            <CloseIcon />
          </TouchableOpacity>
        </View>
        <Divider style={{ marginBottom: "2%", marginTop: "2%" }} />
        <View style={{ flexDirection: "row", flex: 1 }}>
          <Text style={{ fontSize: 15, fontWeight: "bold", marginRight: "2%", marginLeft: "10%" }}>Total Mínimo:</Text>
          <Text style={{ fontSize: 15 }}>{CurrencyFormatter.format(min_suggested_total || 0)}</Text>
        </View>
        <View style={{ flexDirection: "row", flex: 1 }}>
          <Text style={{ fontSize: 15, fontWeight: "bold", marginRight: "2%", marginLeft: "10%" }}>
            Total Sugerido:
          </Text>
          <Text style={{ fontSize: 15 }}>{CurrencyFormatter.format(suggested_total || 0)}</Text>
        </View>
        <View style={{ flexDirection: "row", flex: 1 }}>
          <Text style={{ fontSize: 15, fontWeight: "bold", marginRight: "2%", marginLeft: "10%", marginTop: "1%" }}>
            Total:
          </Text>
          <TextInput
            defaultValue={quote.subtotal.toFixed(2)}
            keyboardType="numeric"
            onChangeText={(value) => setInput(parseFloat(value))}
            style={{
              borderWidth: 2,
              borderRadius: 4,
              marginBottom: "3%",
              paddingLeft: 3,
              borderColor: "#E0E0E0",
            }}
          ></TextInput>
        </View>
        <View style={{ flexDirection: "row", flex: 1 }}>
          <Text style={{ fontSize: 15, fontWeight: "bold", marginRight: "2%", marginLeft: "10%" }}>
            Margen de Contribución:
          </Text>
          <Text style={{ fontSize: 15 }}>
            {parseFloat((margin * 100).toFixed(2)) > 0 && !Number.isNaN(input) ? (margin * 100).toFixed(2) : 0} %
          </Text>
        </View>
        <Divider style={{ marginBottom: "2%" }} />
        <View style={{ flexDirection: "row", justifyContent: "flex-end", marginRight: "5%" }}>
          <Button
            variant="contained"
            disabled={input <= min_suggested_total || Number.isNaN(input)}
            sx={{
              backgroundColor: "#671E75",
              textTransform: "none",
              marginTop: "2%",
              ":hover": {
                bgcolor: "#8f21aa",
              },
            }}
            onClick={() => onSave(input)}
          >
            Guardar
          </Button>
        </View>
      </View>
    </Modal>
  );
};
