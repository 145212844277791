import { Box, IconButton, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { Home, User } from "lucide-react";

export default function NavUserInfo({
  usrName,
  usrEmail,
  homeFunction,
}: {
  usrName: any;
  usrEmail: any;
  homeFunction: () => void;
}) {
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return (
    <Box display={"flex"} alignItems={"center"} gap={"2rem"} padding={"0 5% 0"}>
      <Box display={"flex"} alignItems={"center"} gap={".5rem"}>
        <User color="white" size={30} />
        <Box>
          <Typography style={{ color: "white", fontWeight: 700 }}>{usrName}</Typography>
          <Typography style={{ color: "white", fontSize: "11px" }}>{usrEmail}</Typography>
        </Box>
      </Box>
      <LightTooltip title="Home">
        <IconButton onClick={() => homeFunction()}>
          <Home color="white" size={30} />
        </IconButton>
      </LightTooltip>
    </Box>
  );
}
