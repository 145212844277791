import { TextField } from "@mui/material";

export const Field = ({ label, onChangeText }: { label: string; onChangeText: (e: any) => void }) => {
  return (
    <TextField
      fullWidth
      label={label}
      variant="standard"
      onChange={onChangeText}
      color="secondary"
      sx={{
        "&& .MuiInput-root:hover::before": { borderBottomColor: "gray" },
        "& .MuiInput-underline::after": {
          borderBottom: "1px solid purple",
        },
      }}
    />
  );
};
