import React, { useRef, useState, MutableRefObject, useEffect } from "react";
import { SafeAreaView, View, Text, TextInput, Image, StyleSheet } from "react-native";
import { Modal, IconButton, Button, Box, Typography, Snackbar, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { emptyDoctor } from "../../common/emptyObjects";
import Stack from "@mui/material/Stack";
import { AlertDialog } from "./ModalAlert";
import doctorServices from "../../services/doctorServices";
import { IDoctor } from "../../common/Interfaces";
import { Field } from "../../widgets/Field";
import { borderRadius } from "@mui/system";
import { Toaster } from "../../widgets/Toaster";

export const RegisterDoctor = ({
  showModal,
  onCancel,
  onSave,
  refreshDoctorsList,
}: {
  showModal: boolean;
  onCancel: () => void;
  onSave: () => void;
  refreshDoctorsList: () => void;
}) => {
  const firstSurnameInput = useRef() as MutableRefObject<HTMLDivElement>;
  const secondSurnameInput = useRef() as MutableRefObject<HTMLDivElement>;
  const emailInput = useRef() as MutableRefObject<HTMLDivElement>;
  const professionalLicenseInput = useRef() as MutableRefObject<HTMLDivElement>;
  const specialityInput = useRef() as MutableRefObject<HTMLDivElement>;
  const phoneInput = useRef() as MutableRefObject<HTMLDivElement>;
  const specialityCardInput = useRef() as MutableRefObject<HTMLDivElement>;
  const officeAddressInput = useRef() as MutableRefObject<HTMLDivElement>;
  const assistantNameInput = useRef() as MutableRefObject<HTMLDivElement>;

  const [doctor, setDoctor] = useState<IDoctor>({ ...emptyDoctor });
  const [errors, setErrors] = useState<string>("");

  const [registerDisabled, setRegisterDisabled] = useState<boolean>(true);
  const registerColor = () => (registerDisabled ? "light" : "#671E75");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [titleAlert, setTitleAlert] = useState("");
  const [messageAlert, setMessageAlert] = useState("");
  const [funtionAlert, setFuntionAlert] = useState<Function>(() => {});

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    success: false,
  });

  useEffect(() => {
    const canRegister =
      doctor.names.length !== 0 && doctor.names !== "" &&
      doctor.first_surname.length !== 0 && doctor.first_surname !== "" &&
      doctor.email.length !== 0 && doctor.email !== "" &&
      doctor.phone.length === 10 && parseInt(doctor.phone,10) !== 0 &&
      doctor.professional_license.length !== 0 && parseInt(doctor.professional_license,10) !== 0;
    setRegisterDisabled(!canRegister);
  }, [doctor, setDoctor]);

  const save = () => {
    onSave();
    setDoctor({ ...emptyDoctor });
  };

  //clean inputs from modal and errors
  const clean = () => {
    setDoctor({ ...emptyDoctor });
    setErrors("");
  };

  //close modal
  const cancel = () => {
    onCancel();
    clean();
  };

  const validate_email = (email: string) => {
    let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (email.match(pattern) === null) {
      return false;
    } else {
      return true;
    }
  };

  const submit = () => {
    if (validate_email(doctor.email)) {
      let data = {
        email: doctor.email,
        speciality_card: doctor.speciality_card,
        professional_license: doctor.professional_license,
      };

      let repeated_fields: string[] = [];
      doctorServices
        .validateDoctor(data)
        .then((res) => {
          if (res.professional_license > 0) {
            if (doctor.professional_license && res.professional_license != 0) {
              repeated_fields.push("Cédula profesional");
            }
            setSnackbar({
              open: true,
              message: "Ya existen registros con los mismos datos para los siguientes campos: " + repeated_fields.join(", "),
              success: false,
            });
          } else {
            // Guardamos al médico
            insertDoctor();
            cancel();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setErrors("Email es un campo requerido");
    }
  };

  const insertDoctor = () => {
    doctorServices.insertDoctor(doctor).then((res) => {
      if (res === 200) {
        refreshDoctorsList();
        onCancel();
        setSnackbar({
          open: true,
          message: "Médico se ha guardado correctamente, aparecerá en la lista de doctores.",
          success: true,
        });
      } else {
        onCancel();
        setSnackbar({
          open: true,
          message: "Error: Médico no ha sido guardado, favor de intentar nuevamente.",
          success: false,
        });
      }
    });
  };
  return (
    <>
      <Modal open={showModal} onClose={() => cancel()}>
        <Box sx={{ ...style, width: "40vw" }}>
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Typography variant="h6" style={{ fontWeight: 600, color: "#671E75" }}>
              Registro de médico
            </Typography>
            <IconButton aria-label="delete" onClick={() => cancel()}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} gap={"20px"}>
            <Box width={"50%"} display={"flex"} flexDirection={"column"} gap={".5rem"}>
              <Field label="Nombre(s) *" onChangeText={(e) => setDoctor({ ...doctor, names: e.target.value })} />
              <Field
                label="Segundo apellido"
                onChangeText={(e) => setDoctor({ ...doctor, second_surname: e.target.value })}
              />
              <Field
                label="Cédula profesional *"
                onChangeText={(e) => setDoctor({ ...doctor, professional_license: e.target.value })}
              />
              <Field label="Teléfono *" onChangeText={(e) => setDoctor({ ...doctor, phone: e.target.value })} />
              <Field
                label="Dirección de consultorio"
                onChangeText={(e) => setDoctor({ ...doctor, office_address: e.target.value })}
              />
            </Box>
            <Box width={"50%"} display={"flex"} flexDirection={"column"} gap={".5rem"}>
              <Field
                label="Primer apellido *"
                onChangeText={(e) => setDoctor({ ...doctor, first_surname: e.target.value })}
              />
              <Field
                label="Correo electrónico *"
                onChangeText={(e) => setDoctor({ ...doctor, email: e.target.value })}
              />
              <Field
                label="Especialidad"
                onChangeText={(e) => setDoctor({ ...doctor, speciality: e.target.value })}
              />
              <Field
                label="Cédula de especialidad"
                onChangeText={(e) => setDoctor({ ...doctor, speciality_card: e.target.value })}
              />
              <Field
                label="Nombre asistente"
                onChangeText={(e) => setDoctor({ ...doctor, assistant_name: e.target.value })}
              />
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              disabled={registerDisabled}
              sx={{
                backgroundColor: registerColor,
                textTransform: "none",
                marginTop: "3%",
                ":hover": {
                  bgcolor: "#9D58AA",
                },
              }}
              onClick={() => submit()}
            >
              Registrar
            </Button>
          </Box>
        </Box>
      </Modal>
      <Toaster
        openSnackbar={snackbar.open}
        closeSnackbar={() => setSnackbar({ ...snackbar, open: false })}
        snackbarMessage={snackbar.message}
        success={snackbar.success}
      />
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
