import { LOGIN_REQUEST, PUBLIC_CLIENT_APPLICATION } from "../../authConfig";
import { GEN_URL, headers } from "../common/config";

const signIn = async () => {
  await PUBLIC_CLIENT_APPLICATION.handleRedirectPromise();

  const loginResponse = await PUBLIC_CLIENT_APPLICATION.loginPopup(LOGIN_REQUEST);
  if (loginResponse.account) {
    PUBLIC_CLIENT_APPLICATION.setActiveAccount(loginResponse.account);
  }
  return loginResponse;
};

const signOut = async () => {
  await PUBLIC_CLIENT_APPLICATION.logoutRedirect();
  localStorage.clear();
};

const authorizeConfig = async (accessToken: any) => {
  return fetch(GEN_URL(`/authorize`), {
    method: "POST",
    headers: headers,
    body: JSON.stringify(accessToken),
  }).then((res) => {
    return res.json();
  });
};

const getUsersGroup = async (token: any) => {
  return fetch("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$select=displayName,id", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json());
};

export default { signIn, signOut, authorizeConfig, getUsersGroup };
