import React, { useState, useRef, useEffect, MutableRefObject } from "react";
import { StyleSheet, Text, View, Image, TextInput } from "react-native";
import { Stack, Modal, MenuItem, Button, Box, Typography, TextField } from "@mui/material";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { IPatient } from "../../common/Interfaces";
import { emptyPatient } from "../../common/emptyObjects";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { AlertDialog } from "./ModalAlert";
import patientServices from "../../services/patientServices";
import { width } from "@mui/system";
import { Field } from "../../widgets/Field";
import { Toaster } from "../../widgets/Toaster";
import InputLabel from '@mui/material/InputLabel';

export const RegisterPatientNew = ({
  showModal,
  onClose,
  onSave,
}: {
  showModal: boolean;
  onClose: () => void;
  onSave: (id: string) => void;
}) => {
  const today = new Date();

  const firstSurnameInput = useRef() as MutableRefObject<HTMLDivElement>;
  const secondSurnameInput = useRef() as MutableRefObject<HTMLDivElement>;
  const emailInput = useRef() as MutableRefObject<HTMLDivElement>;

  const [patient, setPatient] = useState<IPatient>({ ...emptyPatient });
  const [birthdate, setBirthdate] = useState<Date>(today);

  const [isDatePickerVisible, setDatePickerVisibility] = useState<boolean>(true);

  const [registerDisabled, setRegisterDisabled] = useState<boolean>(true);
  const registerColor = () => (registerDisabled ? "light" : "#671E75");

  //validate errors in forms
  const [errors, setErrors] = useState<string>("");

  const [value, setValue] = React.useState<Dayjs | null>(null);
  const [cleared, setCleared] = React.useState<boolean>(false);
  const [genero, setGenero] = React.useState("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [titleAlert, setTitleAlert] = useState("");
  const [messageAlert, setMessageAlert] = useState("");
  const [funtionAlert, setFuntionAlert] = useState<Function>(() => {});

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    success: false,
  });

  const handleChange = (event: SelectChangeEvent) => {
    setGenero(event.target.value);
    setPatient({
      ...patient,
      gender: event.target.value,
    });
  };
  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  const changeBirthdate = (date: Date) => {
    const seconds = (today.getTime() - date.getTime()) / 1000;
    const hours = seconds / (60 * 60);
    const days = hours / 24;
    const years = days / 365;

    setBirthdate(date);
    setPatient({
      ...patient,
      age: Math.floor(years),
      birthdate: date.toISOString().substring(0, 10),
    });
    setDatePickerVisibility(false);
  };

  useEffect(() => {
    const canRegister =
      patient.names.length !== 0 &&
      patient.first_surname.length !== 0 &&
      patient.birthdate.length !== 0 &&
      patient.email.length !== 0;
    setRegisterDisabled(!canRegister);
  }, [patient, setPatient]);

  const clearPatient = () => {
    //if inpyt required are empty send an alert "Patient is empty, please fill the required data"
    //let cleanForm =  (document.getElementById("inputPatient") as HTMLFormElement).value;
    //if (form) (form as HTMLFormElement).reset();
    setPatient({
      ...patient,
      names: "",
      first_surname: "",
      second_surname: "",
      gender: "",
      phone: "",
      email: "",
      age: 0,
      birthdate: "",
    });
  };

  const createPatient = () => {
    let id = "";

    patientServices.insertPatient(patient).then((res) => {
      if (res?.status == 201) {
        setSnackbar({
          open: true,
          message: "Paciente guardado correctamente",
          success: true,
        });

        id = res?.patient_id;
        onSave(id);
        clean();
      } else {
        setSnackbar({
          open: true,
          message: "Ya existe un registro con el correo proporcionado.",
          success: false,
        });
      }
    });
  };
  //clean inputs end errors
  const clean = () => {
    setPatient({ ...emptyPatient });
    setErrors("");
  };

  const cancel = () => {
    onClose();
    clean();
  };

  const validate_email = (email: string) => {
    let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (email.match(pattern) === null) {
      return false;
    } else {
      return true;
    }
  };

  const submit = () => {
    if (validate_email(patient.email)) {
      //save the patient
      createPatient();
      //close modal
      cancel();
    } else {
      setErrors("Campo 'Email' es requerido");
      setTitleAlert("Campo 'Email' es requerido");
      setMessageAlert("");
      setFuntionAlert(() => setShowAlert(false));
      setShowAlert(true);
    }
  };

  const handleDateChange = (newValue: any) => {
    const date = new Date(newValue);
    if (date instanceof Date && !isNaN(date.getTime())) {
      var dateData =
        newValue?.toDate().getFullYear() + "-" + newValue?.toDate().getMonth() + "-" + newValue?.toDate().getDay();
      const newdate = new Date(dateData);
      !isNaN(date.getTime()) && changeBirthdate(newdate);
    }
  };

  return (
    <>
      <Modal open={showModal} onClose={() => cancel()}>
        <Box sx={{ ...style, width: "40vw" }}>
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Typography variant="h6" style={{ fontWeight: 600, color: "#671E75" }}>
              Registro de paciente
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={() => {
                cancel();
                setValue(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} gap={"20px"}>
            <Box width={"50%"} display={"flex"} flexDirection={"column"} gap={".5rem"}>
              <Field label="Nombre(s) *" onChangeText={(e) => setPatient({ ...patient, names: e.target.value })} />
              <Field
                label="Segundo Apellido"
                onChangeText={(e) => setPatient({ ...patient, second_surname: e.target.value })}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  label="Fecha de nacimiento *"
                  fullWidth
                  sx={{
                    "&& .MuiInput-root:hover::before": { borderBottomColor: "gray" },
                    "& .MuiInput-underline::after": {
                      borderBottom: "1px solid purple",
                    },
                  }}
                  size="small"
                  value={value}
                  variant="standard"
                  format="DD/MM/YYYY"
                  color="secondary"
                  onChange={(newValue) => {
                    handleDateChange(newValue);
                  }}
                  onClear={() => {
                    setCleared(true);
                    setPatient({
                      ...patient,
                      birthdate: "",
                      age: 0,
                    });
                  }}
                  clearable
                />
              </LocalizationProvider>

              <Field label="Teléfono" onChangeText={(e) => setPatient({ ...patient, phone: e.target.value })} />
            </Box>
            <Box width={"50%"} display={"flex"} flexDirection={"column"} gap={".5rem"}>
              <Field
                label="Primer apellido *"
                onChangeText={(e) => setPatient({ ...patient, first_surname: e.target.value })}
              />
              <Field
                label="Correo electrónico *"
                onChangeText={(e) => setPatient({ ...patient, email: e.target.value })}
              />
              <TextField
                disabled
                fullWidth
                label={"Edad"}
                variant="standard"
                value={patient.age.toString()}
                color="secondary"
                sx={{
                  "&& .MuiInput-root:hover::before": { borderBottom: "1px solid gray" },
                  "& .MuiInput-underline": { borderBottom: "1px solid gray" },
                }}
              />
              <FormControl>
                <InputLabel id="patient-gender-label">Género</InputLabel>
                <Select
                  labelId="patient-gender-label"
                  size="small"
                  placeholder="Seleccionar"
                  variant="standard"
                  color="secondary"
                  sx={{
                    width: "97%",
                    marginTop: "1rem",
                    borderBottom: "1px solid #c8c8c8",
                  }}
                  onChange={handleChange}
                >
                  <MenuItem value="M">Masculino</MenuItem>
                  <MenuItem value="F">Femenino</MenuItem>
                  <MenuItem value="O">Otro</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              disabled={registerDisabled}
              sx={{
                backgroundColor: registerColor,
                textTransform: "none",
                marginTop: "2%",
                ":hover": {
                  bgcolor: "#9D58AA",
                },
              }}
              onClick={() => {
                createPatient();
                setValue(null);
              }}
            >
              Registrar
            </Button>
          </Box>
        </Box>
      </Modal>
      <Toaster
        openSnackbar={snackbar.open}
        closeSnackbar={() => setSnackbar({ ...snackbar, open: false })}
        snackbarMessage={snackbar.message}
        success={snackbar.success}
      />
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
