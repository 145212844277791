export const SECURE_STORAGE = {
    REFRESH_TOKEN: "REFRESH_TOKEN",
}

export const STORAGE = {
    TENANT_ID: "TENTANT_ID",
    CLIENT_ID: "CLIENT_ID",
    SCOPES: "SCOPES",
    EXPIRATION_DATA: "EXPIRATION_DATA",
    ACCESS_TOKEN: "ACCESS_TOKEN",
    NAMES: "NAMES",
    EMAIL: "EMAIL",
}

export const DATA_STORAGE ={
    DOCTORS_DATA: 'DOCTORS_DATA',
    PATIENTS_DATA: 'PATIENTS_DATA',
    HOSPITALS_DATA: 'HOSPITALS_DATA',
    LAST_PATIENTS_DATA: 'LAST_PATIENTS_DATA',
    COSTS: 'COSTS_DATA',
    NEW_DOCTORS_DATA: 'NEW_DOCTORS_DATA',
    NEW_PATIENTS_DATA: 'NEW_PATIENTS_DATA',
    NEW_QUOTES_DATA: 'NEW_QUOTES_DATA',
}

export const QuoteStatus = {
    NEW: "new",
    EDITED: "edited",
    FINISHED: "finished"
  }
