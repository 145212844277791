export const ROUTES = {
  HOME: "Inicio",
  HOME_TEST: "Inicio test",
  LOGIN: "Iniciar Sesión",
  FIND_DOCTOR: "Seleccionar Médico",
  DOCTOR_QUOTES: "Cotizaciones por Médico",
  EDIT_QUOTE: "Editar Cotización",
  QUOTE_SUMMARY: "Resumen de Cotización",
  QUOTE_LIST: "Cotizaciones",
};
