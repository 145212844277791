import React from "react";
import { LinearGradient } from "expo-linear-gradient";
import { ROUTES } from "../common/routes";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as SecureStore from "expo-secure-store";
import { SECURE_STORAGE, STORAGE } from "../common/storage_keys";
import { Platform } from "react-native";
import { Box, Button, Grid, styled, Typography } from "@mui/material";

const config = { dependencies: { "linear-gradient": LinearGradient } };

export const HomeTest = ({ navigation }: { navigation: any }) => {
  const logout = () => {
    navigation.navigate(ROUTES.LOGIN);

    if (Platform.OS !== "web") {
      SecureStore.deleteItemAsync(SECURE_STORAGE.REFRESH_TOKEN);
    } else {
      AsyncStorage.removeItem(SECURE_STORAGE.REFRESH_TOKEN);
    }

    AsyncStorage.multiRemove([STORAGE.EXPIRATION_DATA, STORAGE.ACCESS_TOKEN, STORAGE.NAMES, STORAGE.EMAIL]);
  };

  const openFindDoctor = () => navigation.push(ROUTES.FIND_DOCTOR);
  const openQuoteList = () => navigation.push(ROUTES.QUOTE_LIST);

  return (
    <div
      style={{
        height: "100vh",
        backgroundSize: "100% 100%",
        backgroundPosition: "0px 0px",
        backgroundImage: `linear-gradient(180deg, #671E75 0%, #A12CB8 99%)`,
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} padding={"1rem 2rem 2rem"}>
        <img src={require("../assets/cei_complete.png")} width={"25%"} />
        <Button variant="contained" onClick={() => logout()} style={{ backgroundColor: "#DD163A" }}>
          <Typography style={{ color: "white", textTransform: "capitalize" }}>Cerrar Sesión</Typography>
        </Button>
      </Box>

      <Box display={"flex"} alignItems={"center"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={"2rem 10rem 2rem"}
        >
          <Typography
            variant="h3"
            style={{
              color: "white",
              fontWeight: "500",
              paddingBottom: "5%",
            }}
          >
            Cotizador de Cirugías
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <MenuButton variant="contained" onClick={() => openQuoteList()}>
                <Typography variant="h6" style={{ color: "#671E75" }}>
                  Listado de cotizaciones
                </Typography>
                <img src={require("../assets/list_quote.png")} alt="Listado de cotizaciones" width={"30%"} />
              </MenuButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <MenuButton variant="contained" onClick={() => openFindDoctor()}>
                <Typography variant="h6" style={{ color: "#671E75" }}>
                  Agregar cotizaciones
                </Typography>
                <img src={require("../assets/new_quote.png")} alt="Agregar cotizaciones" width={"30%"} />
              </MenuButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

const MenuButton = styled(Button)(() => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  minHeight: "30vh",
  "&:hover": {
    background: "white",
  },
}));
