import { PublicClientApplication } from "@azure/msal-browser";
import { makeRedirectUri } from "expo-auth-session";

// CEI
const MSAL_CONFIG = {
  auth: {
    clientId: "b9e5af11-3458-48b1-8096-64dc2a37e8f5",
    authority: "https://login.microsoftonline.com/01865ee5-5fc5-4560-a679-4a07187b49c5/oauth2/v2.0/token",
    redirectUri: makeRedirectUri({
      scheme: "cotizadorcirugiasmuguerza",
      path: "auth",
    }),
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", // "sessionStorage" or"localStorage"
    storeAuthStateInCookie: false,
  },
};

const LOGIN_REQUEST = {
  scopes: ["openid", "profile", "email", "offline_access"],
};

const PUBLIC_CLIENT_APPLICATION = new PublicClientApplication(MSAL_CONFIG);

async function initializeMsal() {
  await PUBLIC_CLIENT_APPLICATION.initialize();
}

initializeMsal();

export { MSAL_CONFIG, PUBLIC_CLIENT_APPLICATION, LOGIN_REQUEST };
