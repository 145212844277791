import { Alert, Snackbar } from "@mui/material";

export const Toaster = ({
  openSnackbar,
  closeSnackbar,
  snackbarMessage,
  success,
}: {
  openSnackbar: boolean;
  closeSnackbar: () => void;
  snackbarMessage: string;
  success: boolean;
}) => {
  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert severity={success ? "success" : "error"} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};
