import React from "react";
import { IDoctor, IPatient, IHospital } from "../common/Interfaces";
import { LinearGradient } from "expo-linear-gradient";
import { Box, Typography } from "@mui/material";

const config = { dependencies: { "linear-gradient": LinearGradient } };

export const SideQuoteInfo = ({
  quote_number,
  doctor,
  patient,
  procedure,
  hospital,
  total,
}: {
  quote_number?: string;
  doctor: IDoctor;
  patient?: IPatient;
  procedure?: string;
  hospital?: IHospital;
  total?: string;
}) => {
  return (
    <Box>
      <Box width={"80%"} display={"flex"} flexDirection={"column"} gap={"1rem"} padding={"0 10% 0"}>
        {quote_number !== undefined && quote_number !== "" && (
          <Box>
            <Typography variant="h5" color={"white"} fontWeight={600}>
              Cotización
            </Typography>
            <Typography color={"white"}>{quote_number.toUpperCase()}</Typography>
          </Box>
        )}

        {doctor && (
          <Box>
            <Typography variant="h5" color={"white"} fontWeight={600}>
              Médico:
            </Typography>
            <Typography color={"white"}>
              {doctor.names.toUpperCase()} {doctor.first_surname.toUpperCase()} {doctor.second_surname.toUpperCase()}
            </Typography>
            <Typography color={"white"} fontSize={12}>
              {doctor.speciality}
            </Typography>
          </Box>
        )}

        {hospital !== undefined && (
          <Box>
            <Typography variant="h5" color={"white"} fontWeight={600}>
              Hospital:
            </Typography>
            <Typography color={"white"}>{hospital.display_name}</Typography>
          </Box>
        )}

        {patient !== undefined && (
          <Box>
            <Typography variant="h5" color={"white"} fontWeight={600}>
              Paciente:
            </Typography>
            <Typography color={"white"}>
              {patient.names.toUpperCase()} {patient.first_surname.toUpperCase()} {patient.second_surname.toUpperCase()}
            </Typography>
          </Box>
        )}

        {procedure !== undefined && procedure !== "" && (
          <Box>
            <Typography variant="h5" color={"white"} fontWeight={600}>
              Procedimiento:
            </Typography>
            <Typography color={"white"}>{procedure}</Typography>
          </Box>
        )}

        {total !== undefined && total !== "" && (
          <Box>
            <Typography variant="h5" color={"white"} fontWeight={600}>
              Total:
            </Typography>
            <Typography color={"white"}>{total}</Typography>
            <Typography color={"white"} fontSize={12}>
              *IVA incluido
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
